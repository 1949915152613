import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import BrFlag from '@/assets/icons/br-flag.svg';
import EsFlag from '@/assets/icons/es-flag.svg';
import UsFlag from '@/assets/icons/us-flag.svg';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '@/common/components/Dropdown';
import i18n from '@/config/i18n';

import * as styles from './styles';

export function LanguageMenuDropdown() {
  const { t } = useTranslation('common');
  const [refreshKey, setRefreshKey] = useState(0);

  const handleChangeLanguage = (value: string) => {
    i18n.changeLanguage(value);
    setRefreshKey(prevKey => prevKey + 1);
  };

  const languageOptions = [
    { label: t('Portuguese'), value: 'pt-BR', icon: BrFlag },
    { label: t('English'), value: 'en-US', icon: UsFlag },
    { label: t('Spanish'), value: 'es-ES', icon: EsFlag },
  ];

  const selectedLanguage = languageOptions.find(
    option => option.value === i18n.language,
  );

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className={styles.dropButton()}>
        {selectedLanguage ? (
          <div className="flex flex-row items-center">
            <img
              className={styles.icon()}
              alt={`${selectedLanguage.label} flag`}
              src={selectedLanguage.icon}
            />
            <span className={styles.text()}>{selectedLanguage.label}</span>
          </div>
        ) : (
          <span className={styles.text()}>{t('SelectLanguage')}</span>
        )}

        <ChevronDown className={styles.icon()} size="20" />
      </DropdownMenuTrigger>

      <DropdownMenuContent className="min-w-[11.5rem]">
        <DropdownMenuRadioGroup
          value={selectedLanguage?.value}
          onValueChange={handleChangeLanguage}
        >
          {languageOptions?.map(option => (
            <DropdownMenuRadioItem
              key={option.value}
              className="px-4 py-2"
              value={option.value}
            >
              <img
                className={styles.icon()}
                alt={`${option.label} flag`}
                src={option.icon}
              />

              {option.label}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
